<template>
  <MainLayout>
    <template v-slot:toolbar>
      <div class="d-block w-100">
        <div class="mt-1">
          <AlertWraper
            :content="error"
            type="error"
          />
          <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-inline-flex">
              <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Gerenciar Audiências</h1>
            </div>
            <!--end::Title-->
          </div>

          <div class="card card-flush mt-5">
            <div class="card-body">
              <div class="description fs-6 mt-3">
                <p class=""> Faça o upload de um arquivo (.csv ou .xlsx) para gerenciar as audiências salvas no sistema.</p>
                <p>A partir do momento em que enviar uma nova planilha, todas as audiências serão desativadas no sistema, mantendo ativas somente as audiências incluidas na planilha enviada.</p>
                <p>Siga o exemplo abaixo:</p>
              </div>
              <!--begin::File Sample-->
              <div class="m-auto w-50 mt-5 mb-4">
                <table class="excel-table">
                  <thead>
                  <tr>
                    <th>Nome da audiência</th>
                    <th>ID da audiência</th>
                    <th>Tipo de Audiência</th>
                    <th>Prioridade</th>
                    <th>Plataforma</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Board Games</td>
                    <td>12345</td>
                    <td>EMAIL_AUDIENCE</td>
                    <td>10</td>
                    <td>GOOGLE_ADS</td>
                  </tr>
                  <tr>
                    <td>Teste testando</td>
                    <td>67890</td>
                    <td>SITE_LIST</td>
                    <td>9</td>
                    <td>GOOGLE_ADS</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--end::File Sample-->

              <div class="m-auto w-50 mt-5 d-inline-flex">
                <!--begin::Form-->
                <form class="default-form" @submit.prevent="">
                  <LoaderOverlay
                    size="big"
                    color="color2"
                    v-if="loader"
                  />
                  <label class="form-label required fw-semibold mb-0 me-2" for="file"> Selecione um arquivo</label>
                  <input type="file" accept=".csv, .xlsx" id="file" @change="setFile" class="form-control mt-2">
                </form>
                <!--end::Form-->
              </div>

            </div>
            <!--begin:Action-->
            <div class="mt-3 mb-5 text-center">
              <Button
                class="btn btn-primary"
                @click="submit"
                :loading="loader"
                :disabled="!form.file"
              >
                Salvar
              </Button>
            </div>
            <!--end:Action-->
          </div>
        </div>
      </div>
    </template>
  </MainLayout>
</template>
<script>
import Audiences from '@/modules/audiences/services/audiences-service'
import Button from '@/components/common/Button/Button.vue'
import NotificationService from '@/modules/notifications/services/notifications-service'
import MainLayout from '@/components/layouts/MainLayout.vue'
import AlertWraper from '@/components/common/Alert/AlertWraper.vue'
import { parseErrorResponseToArray } from '@/utils'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay.vue'

export default {
  name: 'AudienceEdit',
  components: {
    LoaderOverlay,
    AlertWraper,
    MainLayout,
    Button
  },
  data () {
    return {
      error: null,
      form: {
        file: null
      },
      loader: false,
      table: [
        {
          name: 'Exemplo 1',
          id: 12345,
          type: 'EMAIL_AUDIENCE',
          priority: 10,
          platform: 'GOOGLE_ADS'
        },
        {
          name: 'Exemplo 2',
          id: 12345,
          type: 'SITE_LIST',
          priority: 10,
          platform: 'GOOGLE'
        }
      ]
    }
  },
  methods: {
    /*
     *  Submits form
     */
    async submit () {
      this.loader = true

      try {
        await Audiences.upsertAudiences(this.form)
        const notification = {
          notification: {
            type: 'success',
            content: 'Arquivo recebido com sucesso!'
          }
        }

        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        this.error = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Sets file to form data
     */
    setFile (e) {
      this.form.file = e.target.files[0]
    }
  }
}
</script>
<style scoped>

.excel-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.excel-table th, .excel-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}
.excel-table th {
  background-color: #f9f9f9;
  color: black;
  font-weight: bold;
}
.excel-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
</style>
